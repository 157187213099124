import React, { useState } from 'react'; 
import { FaChevronUp, FaChevronDown, FaHeart } from 'react-icons/fa';
import { HiArrowRight } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import CarouselPic from '../components/pic_carousel';
import axios from 'axios';
import { getCsrfToken } from '../utils';
import Cookies from 'js-cookie';

const UserPreferencesDropdown = ({ onPreferenceChange }) => {
    const { t } = useTranslation();
    
    const preferencesOptions = [
        { value: 'best_price', label: t('Best Price') },
        { value: 'local', label: t('Local') },
    ];

    const [selectedPreference, setSelectedPreference] = useState(preferencesOptions[0].value);
    const [isOpen, setIsOpen] = useState(false);  // Pour gérer l'état du menu déroulant

    const handlePreferenceChange = (value) => {
        setSelectedPreference(value);
        if (onPreferenceChange) {
            onPreferenceChange(value);  // Propagation de la nouvelle préférence vers le parent
        }
        setIsOpen(false); // Fermer le menu après avoir sélectionné une option
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="relative rounded-2xl ml-4 border-2 border-black h-8 w-56 flex items-center justify-between">
            <div 
                onClick={toggleMenu} 
                className="flex items-center justify-between w-full cursor-pointer p-2"
            >
                <span className="ml-4">{preferencesOptions.find(option => option.value === selectedPreference)?.label}</span>
                {isOpen ? <FaChevronUp className="mr-2 text-gray-600" /> : <FaChevronDown className="mr-2 text-gray-600" />}
            </div>
            
            {/* Menu déroulant */}
            {isOpen && (
                <div className="absolute top-full left-0 right-0 bg-white shadow-lg border-2 border-gray-300 mt-1 rounded-xl z-10">
                    {preferencesOptions.map((option) => (
                        <div
                            key={option.value}
                            onClick={() => handlePreferenceChange(option.value)}
                            className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const Product = ({ product, rate, symbol, currency }) => {
    const { t } = useTranslation();
    const [productsCache, setProductsCache] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentProduct, setCurrentProduct] = useState(product);
    const csrfToken = getCsrfToken();
    
    // Fonction utilitaire pour récupérer les produits en fonction de la préférence
    const fetchCategoryProduct = async (preference, asin) => {
        if (productsCache[preference]) {
            // Utilisation du cache si disponible
            setCurrentProduct(productsCache[preference]);
            return;
        }

        setLoading(true);
        setError(null);
        const token = Cookies.get('sessionToken');
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/preference/`,
                {
                    params: { user_preferences: preference, asin },
                    withCredentials: true,
                    headers: {
                        'Authorization': `${token}`,
                        'X-CSRFToken': csrfToken,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (Array.isArray(response.data) && response.data.length > 0) {
                const fetchedProduct = response.data[0];
                setProductsCache((prev) => ({ ...prev, [preference]: fetchedProduct }));
                setCurrentProduct(fetchedProduct);
            } else {
                setError(t('No products found'));
                setCurrentProduct(null);
            }
        } catch (err) {
            if (err.response) {
                setError(`${t('Server error')}: ${err.response.status} ${err.response.statusText}`);
            } else if (err.request) {
                setError(t('Network error: No response from server'));
            } else {
                setError(`${t('Unexpected error')}: ${err.message}`);
            }
        } finally {
            setLoading(false);
        }
    };

    // Fonction pour gérer le changement de préférence
    const handleUserPreferenceChange = (newPreference) => {
        if (newPreference && product?.item_id) {
            fetchCategoryProduct(newPreference, product.item_id); // Requête API avec la nouvelle préférence
        }
    };

    // Générer un label aléatoire sans useMemo pour éviter les boucles infinies
    const randomLabel = () => {
        const labels = [t('Recommanded'), t('Big Deal'), t('Hot Trend')];
        return labels[Math.floor(Math.random() * labels.length)];
    };

    // Conversion de prix
    const getConvertedPrice = (price) => (price ? (price * rate).toFixed(2) : '0.00');

    // Prix convertis
    const convertedPrice = getConvertedPrice(currentProduct?.price);
    const convertedDeliveryPrice = getConvertedPrice(currentProduct?.delivery_price);

    return (
        <div className="bg-white p-4 rounded-lg shadow-lg relative border">
            {/* Label aléatoire */}
            <div className="absolute top-2 left-2 bg-red-500 text-white text-xs px-2 py-1 rounded-full">
                {randomLabel()}
            </div>

            {/* Contenu du produit */}
            <div className="p-4 flex justify-between items-center">
                <p className="font-semibold">
                    {currentProduct?.title?.split(' ').slice(0, 3).join(' ') || t('Loading...')}
                </p>
                <div className="flex flex-col items-end">
                    <p className="text-gray-500">{t('New')}</p>
                    <p className="text-gray-500">
                        {currentProduct?.stock !== 100
                            ? `${currentProduct?.stock} ${t('remain')}`
                            : t('In Stock')}
                    </p>
                </div>
            </div>

            {/* Carousel */}
            <CarouselPic productimg={currentProduct?.image?.[0] || '/default-image.png'} />

            {/* Dropdown de préférence */}
            <UserPreferencesDropdown onPreferenceChange={handleUserPreferenceChange} />

            {/* Détails produit */}
            <div className="flex justify-between items-center p-4 bg-white">
                <div className="flex flex-col space-y-3 font-bold" style={{ fontFamily: 'Cambria' }}>
                    <div className="flex items-center justify-between gap-12">
                        <span className="text-sm">{t('Store')}</span>
                        <span className="text-sm font-medium text-gray-700">
                            {currentProduct?.marketplace || t('N/A')}
                        </span>
                    </div>

                    <div className="flex items-center justify-between">
                        <span className="text-sm">{t('Country')}</span>
                        <span className="text-sm font-medium text-gray-700">
                            {currentProduct?.country || t('Unknown')}
                        </span>
                    </div>

                    <div className="flex items-center justify-between">
                        <span className="text-sm font-bold">{t('Price')}</span>
                        <span className="text-xl font-bold  text-red-600 " style={{ fontFamily: 'Cambria' }}>
                            {convertedPrice} {symbol}
                        </span>
                    </div>

                    <div className="flex items-center justify-between">
                        <span className="text-sm">{t('Delivery')}</span>
                        <span className="text-sm font-medium text-gray-700">
                            {convertedDeliveryPrice} {symbol}
                        </span>
                    </div>
                </div>

                {/* Actions utilisateur */}
                <div className="flex flex-col items-end space-y-5">
                    <a
                        href={currentProduct?.link || '#'}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center space-x-2 px-3 py-1 border border-red-500 rounded-full text-red-600 hover:bg-red-500 hover:text-white text-sm font-semibold transition-all duration-300 transform hover:translate-x-2 hover:scale-105"
                    >
                        <span>{t('Follow')}</span>
                        <HiArrowRight className="text-lg" />
                    </a>

                    <button
                        className="w-10 h-10 rounded-full bg-red-500 flex items-center justify-center text-white shadow-md hover:bg-red-600 hover:text-white transition-transform duration-300 transform hover:scale-110"
                        aria-label={t('Add to favorites')}
                    >
                        <FaHeart className="text-lg" />
                    </button>
                </div>
            </div>

            {/* Indicateur de chargement ou erreur */}
            {loading && <p className="text-center text-gray-500">{t('Loading...')}</p>}
            {error && <p className="text-center text-red-500">{error}</p>}
        </div>
    );
};

export default Product;
