import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getCsrfToken } from '../utils';
import Cookies from 'js-cookie';

const ResetPasswordForm = () => {
  const { uid, token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const csrfToken = getCsrfToken();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = Cookies.get('sessionToken');
    // Validation côté client
    if (password.length < 8) {
      setError('Password must be at least 8 characters long.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    setError(''); // Réinitialiser les erreurs avant la requête

    try {
    await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/reset-password/${uid}/${token}/`,  // Inclure uid et token dans l'URL
        {
            password,  
        },
        {   withCredentials: true,
            headers: {
              'Authorization': `${token}`,
              'X-CSRFToken': csrfToken,
              'Content-Type': 'application/json',
          },
        }
        );
      setSuccess(true);
    } catch (err) {
      const apiError =
        err.response?.data?.error || err.response?.data?.detail || 'An error occurred. Please try again.';
      setError(apiError);
    }
  };

  if (success) {
    return (
      <div className="min-h-screen flex items-center justify-center py-8">
        <div className="bg-white p-8 rounded-lg shadow-lg w-full border max-w-md text-center">
          <h2 className="text-2xl font-semibold text-green-600 mb-6">Password Reset Successful!</h2>
          <p>You can now log in with your new password.</p>
          <a
            href="/login"
            className="mt-6 inline-block bg-red-600 text-white font-semibold rounded-md p-3 hover:bg-red-500 transition duration-300"
          >
            Go to Login
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center py-8">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full border max-w-md">
        <h2 className="text-2xl font-semibold text-center mb-6">Reset Your Password</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <input
              type="password"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
            />
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <div>
            <button
              type="submit"
              className="w-full p-3 bg-red-600 text-white font-semibold rounded-md hover:bg-red-500 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Reset Password
            </button>
          </div>
        </form>
        <p className="text-center text-sm text-gray-500 mt-4">
          Remember your password?{' '}
          <a href="/login" className="text-red-600 hover:underline">
            Login here
          </a>
        </p>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
