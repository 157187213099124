import Cookie from 'js-cookie';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const getSessionToken = async () => {
    try {
        const existingToken = Cookie.get('sessionToken');
        const currentDate = Date.now();
        const oneDayInMs = 24 * 60 * 60 * 1000;

        if (existingToken) {
            const decodedToken = jwtDecode(existingToken);

            if (decodedToken.iat) {
                const tokenAgeInMs = currentDate - decodedToken.iat * 1000;
                if (tokenAgeInMs < oneDayInMs) {
                    console.log('Le jeton est valide.');
                    return existingToken;
                }
            }

            console.log('Le jeton est expiré.');
        }

        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/session-token/`, {
            withCredentials: true,
        });

        console.log('Un nouveau jeton a été généré.');

    } catch (error) {
        console.error('Erreur lors de la récupération du token', error);
        return null;
    }
};

export default getSessionToken;
