import React from 'react';
import ReactDOM from 'react-dom/client'; // Assurez-vous que vous utilisez 'react-dom/client'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import i18n from './i18n';
// Créez un root React
const root = ReactDOM.createRoot(document.getElementById('root'));

// Utilisez la méthode 'root.render()' pour rendre l'application
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// Si vous voulez mesurer la performance de votre app
reportWebVitals();