import './App.css';
import React, { useEffect, useState } from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import CookieConsent from "./components/cookies";
import Router from "./Router";
import ParticlesBackground from './components/ParticulesBackground';
import getSessionToken from './components/JWTToken';

const App = () => {
  const [isLoading, setIsLoading] = useState(true); // État de chargement

  useEffect(() => {
    // Appeler la fonction pour récupérer le jeton dès que l'application démarre
    const initializeSession = async () => {
      await getSessionToken(); // Attendre la récupération du jeton
      setIsLoading(false); // Passer à "non chargé" une fois terminé
    };

    initializeSession();
  }, []); // Le tableau vide [] garantit que cela ne s'exécute qu'une seule fois au montage

  // Affiche un écran de chargement pendant l'initialisation
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p>Chargement en cours...</p>
      </div>
    );
  }

  // Rendre l'application principale après le chargement
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      {/* Conteneur de particules avec ajustement dynamique de la hauteur */}
      <div className="relative flex-grow">
        {/* Afficher le fond de particules seulement sur les écrans larges et plus */}
        <div className="absolute top-0 left-0 right-0 z-0 hidden lg:block">
          <ParticlesBackground />
        </div>
        <main className="relative z-10 flex-grow">
          <Router /> {/* Gestion des routes ici */}
        </main>
      </div>
      <CookieConsent />
      <Footer />
    </div>
  );
};

export default App;
