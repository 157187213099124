import React from "react";

import { Routes, Route } from "react-router-dom";
import CookieManagement from "./pages/CookieManagement";
import LegalNotice from "./pages/LegalNotice";
import ConditionsOfUse from "./pages/ConditionsOfUse";
import PrivacyNotice from "./pages/PrivacyNotice";
import Market from "./pages/Market";
import LandingPage from "./pages/LandingPage";
import Register from "./pages/register";
import Login from "./pages/login";
import ResetPasswordForm from './components/reset-password';
const Router = () => {
    return (
        <Routes> 
            <Route path="/" element={<LandingPage />} />
            <Route path="/cookie-management" element={<CookieManagement />} />
            <Route path="/legal-notice" element={<LegalNotice />} />
            <Route path="/conditions-of-use" element={<ConditionsOfUse />} />
            <Route path="/privacy-notice" element={<PrivacyNotice />} />
            <Route path="/search" element={<Market />} /> 
            <Route path="/login" element={<Login />} /> 
            <Route path="/register" element={<Register />} /> 
            <Route path="/reset-password/:uid/:token" element={<ResetPasswordForm />} />
        </Routes>
    );
};

export default Router;