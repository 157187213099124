import React, { useState, useEffect } from "react";
import logo from "../assets/img/MarketWays-logo.png";
import LanguageCurrency from './lang-currency';
import { useNavigate } from 'react-router-dom';
import Account from './account';
const Header = () => {
    const [isFocused, setIsFocused] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const [query, setSearchValue] = useState('');

    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
        setIsTyping(e.target.value.length > 0);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (query) {
            navigate(`/search?q=${query}`);
        }
        setIsFocused(false);
        setIsTyping(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth); // Met à jour la taille de la fenêtre
        };

        window.addEventListener('resize', handleResize); // Ajoute un écouteur d'événements
        return () => {
            window.removeEventListener('resize', handleResize); // Nettoie l'écouteur d'événements
        };
    }, []);

    const isSmallScreen = windowWidth < 768; // Définit les petits écrans comme ayant une largeur inférieure à 640px

    return (
        <header className="relative w-full p-6 px-12 z-20 bg-fixed">
            {isFocused && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-30 transition-opacity duration-300"
                    onClick={() => setIsFocused(false)}
                ></div>
            )}

            <div className="flex flex-col medium:flex-row justify-between items-center gap-6 small:gap-10">
                {/* Logo Section */}
                <div className="flex items-center justify-between  gap-4 small:gap-6 px-2 small:px-4">
                    {/* Logo and Brand Name Section */}
                    <div className="flex items-center justify-center gap-2">
                        <a href="/" className="inline-block w-16 h-16 flex justify-center items-center">
                            <img 
                                src={logo} 
                                alt="TheMarketways Logo" 
                                className="w-8 h-8 small:w-10 small:h-10" 
                            />
                        </a>
                        <a href="/" className="text-black no-underline">
                            <h1 
                                className="font-black text-2xl small:text-3xl leading-none pb-2 text-center"
                                style={{ fontFamily: 'Cambria' }}
                            >
                                TheMarketWays
                            </h1>
                        </a>
                    </div>


                    {/* Responsive Section for Small Screens */}
                    {isSmallScreen && (
                    <div className="flex items-center gap-4 small:gap-8">
                        <LanguageCurrency />
                        {/* Account Icon - Positioned to the right */}
                        <Account />
                    </div>
                    )}
                </div>

                {/* Formulaire de recherche */}
                <form
                    className="relative flex items-center h-10 small:h-12 w-full small:w-1/2 rounded-full shadow-lg bg-white hover:shadow-xl transition-all duration-300 hover:text-red-600 hover:scale-110"
                    onSubmit={handleSearchSubmit}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                >
                    <button
                        type="submit"
                        className="flex justify-center items-center h-full w-10 bg-transparent border-none cursor-pointer focus:outline-none transition-all duration-300 ease-in-out"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 text-gray-600 transition-colors duration-300 ease-in-out hover:text-red-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <circle cx="10" cy="10" r="7" />
                            <line x1="15" y1="15" x2="20" y2="20" />
                        </svg>
                    </button>
                    <input
                        type="search_query"
                        value={query}
                        onChange={handleInputChange}
                        placeholder={isTyping ? "" : "Search"}
                        className="flex-grow text-sm bg-transparent text-gray-600 h-full pl-2 border-none focus:outline-none"
                    />
                </form>
                
                {/* Affichage pour les grands écrans */}
                <div className="flex items-center gap-4 small:gap-8 hidden medium:flex">
                    <LanguageCurrency />
                    {/* Icone de compte - positionné à droite */}
                    <Account />
                </div>
            </div>
        </header>
    );
};

export default Header;
