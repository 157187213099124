import React from 'react';
import { useTranslation } from "react-i18next";

const PrivacyNotice = () => {
    const { t } = useTranslation();

    return (
        <div className="container mx-auto p-4">
            <h1 style={{ fontSize: '3vh',  fontWeight: 'bolder' }}>
                {t("Privacy Notice")}
            </h1>

            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("We know that you care how information about you is used and shared and we appreciate your trust in us to do that carefully and sensibly. This Privacy Notice explains how we collect, use, and safeguard your information when you visit our website.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("Controllers of Personal Information")}
            </h2>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("TheMarketWays, are data controllers of personal information collected and processed through Our Services.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("What Personal Information Do We Collect?")}
            </h2>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("We collect your personal information in order to provide and continually improve our products and services.")}
            </p>

            <h3 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("Information You Give Us:")}
            </h3>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("We receive and store any information you provide in relation to Our Services. You can choose not to provide certain information but then you might not be able to take advantage of many of our services.")}
            </p>

            <h3 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("Automatic Information:")}
            </h3>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("We automatically collect and store certain types of information about your use of Our Services, including your interaction with content and services available through Our Services. Like many websites, we use cookies and other unique identifiers and we obtain certain types of information when your web browser or device accesses Our Services.")}
            </p>

            <h3 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("Information from Other Sources:")}
            </h3>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("We might receive information about you from other sources, such as updated delivery and address information from our carriers or other third parties, which we use to correct our records and deliver your next purchase more easily.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("For What Purposes Do We Process Your Personal Information?")}
            </h2>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("We process your personal information to operate, provide, and improve the services that we offer our customers. These purposes include:")}
            </p>

            <ul className="list-disc list-inside" style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                <li>{t("Purchase and delivery of products and services.")}</li>
                <li>{t("Provide, troubleshoot, and improve Our Services.")}</li>
                <li>{t("Recommendations and personalization.")}</li>
                <li>{t("Provide voice, image, and camera services.")}</li>
                <li>{t("Advertising.")}</li>
                <li>{t("Fraud prevention and credit risks.")}</li>
                <li>{t("Comply with legal obligations.")}</li>
                <li>{t("Communicate with you.")}</li>
                <li>{t("Purposes for which we seek your consent.")}</li>
            </ul>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("What About Cookies and Other Identifiers?")}
            </h2>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("We use cookies and similar tools to enhance your experience, provide our services, understand how customers use our services so we can make improvements, and display ads. Approved third parties also use these tools in connection with our display of ads.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("Does Our Company Share Your Personal Information?")}
            </h2>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("Information about our customers is an important part of our business and we are not in the business of selling our customers’ personal information to others. We share customers’ personal information only as described below and with subsidiaries that follow practices at least as protective as those described in this Privacy Notice.")}
            </p>

            <h3 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("Transactions involving third parties:")}
            </h3>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("We make available to you services, products, applications, or skills provided by third parties for use on or through Our Services. We share customers' personal information related to those transactions with that third party.")}
            </p>

            <h3 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("Third-party service providers:")}
            </h3>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("We employ other companies and individuals to perform functions on our behalf. These third-party service providers have access to personal information needed to perform their functions but may not use it for other purposes.")}
            </p>

            <h3 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("Business transfers:")}
            </h3>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("As we continue to develop our business, we might sell or buy other businesses or services. In such transactions, customer information generally is one of the transferred business assets.")}
            </p>

            <h3 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("Protection of Our Company and others:")}
            </h3>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("We release account and other personal information when we believe release is appropriate to comply with the law; enforce or apply our conditions of use and other agreements; or protect the rights, property, or safety of Our Company, our users, or others.")}
            </p>

            <h3 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("Transfers out of the European Economic Area:")}
            </h3>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("Whenever we transfer personal information to countries outside of the European Economic Area, we ensure that the information is transferred in accordance with this Privacy Notice and as permitted by applicable data protection laws.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("How Secure is Information About Me?")}
            </h2>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("We design our systems and devices with your security and privacy in mind. We work to protect the security of your personal information during transmission by using encryption protocols and software. We maintain physical, electronic, and procedural safeguards in connection with the collection, storage, and disclosure of personal customer information.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("What About Advertising?")}
            </h2>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("Third-party advertisers and links to other websites and apps: Our Services may include third-party advertising and links to other websites and apps. Third-party advertising partners may collect information about you when you interact with their content, advertising, and services.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("What Information Can I Access?")}
            </h2>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("You can access your information, including your name, address, payment options, profile information, and purchase history in the 'Your Account' section of the website.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("What Choices Do I Have?")}
            </h2>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("If you have any questions or objections as to how we collect and process your personal information, please contact our Customer Service. Many of our services also include settings that provide you with options as to how your data are being used.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("Are Children Allowed to Use Our Services?")}
            </h2>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("We do not sell products for purchase by children. If you're under 18, you may use Our Services only with the involvement of a parent or guardian.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("How Long Do We Keep Your Personal Information?")}
            </h2>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("We keep your personal information to enable your continued use of Our Services, for as long as it is required to fulfil the relevant purposes described in this Privacy Notice, as may be required by law, or as otherwise communicated to you.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                {t("Contacts, Notices & Revisions")}
            </h2>
            <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                {t("If you have any concern about privacy at Our Company or want to contact one of our data controllers, please contact us with a thorough description and we will try to resolve the issue for you. Our business changes constantly and our Privacy Notice will change also. You should check our website frequently to see recent changes.")}
            </p>
        </div>
    );
};

export default PrivacyNotice;
