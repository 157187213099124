import React, { useState, useEffect,useMemo, useCallback } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import axios from 'axios';
import { getCsrfToken } from '../utils';
const SetLanguageCurrency = () => {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(Cookies.get('language') || 'en');
  const [currency, setCurrency] = useState(Cookies.get('currency') || 'GBP');
  const [isOpen, setIsOpen] = useState(false);
  const [isLangOpen, setIsLangOpen] = useState(false);
  const [isCurrOpen, setIsCurrOpen] = useState(false);
  const csrfToken = getCsrfToken();

  const languageCurrencyMap = useMemo(() => ({
    fr: 'EUR',
    es: 'EUR',
    en: 'GBP',
    de: 'EUR',
    it: 'EUR',
  }), []);

  const countryLanguageMap = useMemo(() => ({
    fr: 'fr',
    es: 'es',
    uk: 'en',
    de: 'de',
    it: 'it',
  }), []);

  const updateLanguageCurrency = useCallback((newLanguage, newCurrency) => {
    setLanguage(newLanguage);
    setCurrency(newCurrency);

    i18n.changeLanguage(newLanguage);
    Cookies.set('language', newLanguage, { expires: 365 });
    Cookies.set('currency', newCurrency, { expires: 365 });
  }, [i18n]); 
  
  // Fetch country and set language/currency based on it
  const fetchCountryData = useCallback(async () => {
    const token = Cookies.get('sessionToken');
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cookie-consent`, {
        headers: {
          'Authorization': `${token}`,
          'X-CSRFToken': csrfToken,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
  
      const detectedCountry = response.data?.country;
      if (detectedCountry) {
        const newLanguage = countryLanguageMap[detectedCountry] || 'en';
        const newCurrency = languageCurrencyMap[newLanguage] || 'EUR';
  
        updateLanguageCurrency(newLanguage, newCurrency);
        Cookies.set('country', detectedCountry, { expires: 365 });
      }
    } catch (error) {
      console.error('Error fetching country data:', error.response?.data || error.message);
    }
  }, [csrfToken, countryLanguageMap, languageCurrencyMap, updateLanguageCurrency]);

  // Update language and currency
  

  // Handle cookie change
  const handleCookieChange = useCallback(() => {
    const consentGiven = Cookies.get('cookie_consent');
    const savedCountry = Cookies.get('country');

    if (consentGiven === 'accepted' && !savedCountry) {
      fetchCountryData();
    }
  }, [fetchCountryData]);

  // Storage listener for cookie consent
  const storageListener = useCallback((event) => {
    if (event.key === 'cookie_consent') {
      handleCookieChange();
    }
  }, [handleCookieChange]);

  useEffect(() => {
    // Initialize language and currency from cookies
    const initialLanguage = Cookies.get('language') || 'en';
    const initialCurrency = Cookies.get('currency') || languageCurrencyMap[initialLanguage] || 'EUR';

    updateLanguageCurrency(initialLanguage, initialCurrency);

    // Set up cookie consent listener
    window.addEventListener('storage', storageListener);
    handleCookieChange();

    return () => {
      window.removeEventListener('storage', storageListener);
    };
  }, [languageCurrencyMap, storageListener, handleCookieChange, updateLanguageCurrency]);

  const handleLanguageChange = (lang) => {
    const newCurrency = languageCurrencyMap[lang];
    updateLanguageCurrency(lang, newCurrency);
    setIsLangOpen(false);
  };

  const handleCurrencyChange = (curr) => {
    setCurrency(curr);
    Cookies.set('currency', curr, { expires: 365 });
    setIsCurrOpen(false);
  };

  const toggleLangMenu = () => {
    setIsLangOpen((prev) => !prev);
    setIsCurrOpen(false);
  };

  const toggleCurrMenu = () => {
    setIsCurrOpen((prev) => !prev);
    setIsLangOpen(false);
  };
  const handleSave = () => {
    setIsOpen(false);
    window.location.reload();
  };
  return (
    <div className="inline-block relative hidden lg:block">
      <button
        className="font-serif p-3 text-base rounded-lg outline-none cursor-pointer transition-all duration-100 ease-in-out w-36 flex justify-between items-center hover:bg-white hover:border hover:shadow-lg hover:scale-105"
        style={{ fontFamily: 'Cambria' }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {t('language_currency_button', { language: language.toUpperCase(), currency })}
        {isOpen ? <FaChevronUp className="ml-2 text-gray-600" /> : <FaChevronDown className="ml-2 text-gray-600" />}
      </button>

      {isOpen && (
        <div
          className="absolute right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg w-72 p-4 z-20"
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          <div className="mb-4 relative">
            <label className="block text-lg font-bold mb-2">{t('Language')}</label>
            <div
              className="p-3 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md flex justify-between items-center"
              onClick={toggleLangMenu}
            >
              {language.toUpperCase()}
              {isLangOpen ? <FaChevronUp className="ml-2" /> : <FaChevronDown className="ml-2" />}
            </div>
            {isLangOpen && (
              <div className="absolute mt-2 bg-white border border-gray-300 rounded-lg shadow-lg w-full z-30">
                {['en', 'fr', 'es', 'de', 'it'].map((lang) => (
                  <div
                    key={lang}
                    onClick={() => handleLanguageChange(lang)}
                    className={`p-3 cursor-pointer hover:bg-[#ff6961] hover:text-white ${
                      language === lang ? 'bg-[#ff6961] text-white' : ''
                    }`}
                  >
                    {lang.toUpperCase()}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="mb-6 relative">
            <label className="block text-lg font-bold mb-2">{t('Currency')}</label>
            <div
              className="p-3 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md flex justify-between items-center"
              onClick={toggleCurrMenu}
            >
              {currency}
              {isCurrOpen ? <FaChevronUp className="ml-2" /> : <FaChevronDown className="ml-2" />}
            </div>
            {isCurrOpen && (
              <div className="absolute mt-2 bg-white border border-gray-300 rounded-lg shadow-lg w-full z-30">
                {['EUR', 'USD', 'GBP', 'JPY', 'AUD'].map((curr) => (
                  <div
                    key={curr}
                    onClick={() => handleCurrencyChange(curr)}
                    className={`p-3 cursor-pointer hover:bg-[#ff6961] hover:text-white ${
                      currency === curr ? 'bg-[#ff6961] text-white' : ''
                    }`}
                  >
                    {curr}
                  </div>
                ))}
              </div>
            )}
          </div>
          <button
            onClick={handleSave}
            className="w-full py-3 bg-black text-white font-bold rounded-lg hover:bg-[#ff6961] transition"
          >
            {t('Save')}
          </button>
        </div>
      )}
    </div>
  );
};

export default SetLanguageCurrency;
