import React from "react";
import { useTranslation } from 'react-i18next'; // Importer le hook pour la traduction

const ConditionOfUse = () => {
    const { t } = useTranslation(); // Utiliser le hook t pour les traductions

    return (
        <div className="container mx-auto p-4">
            <h1 style={{ fontSize: '3vh', fontWeight: 'bolder' }}>
                {t("Conditions of Use")}
            </h1>

            <section className="mb-6">
                <h2 style={{ fontSize: '2.5vh', fontWeight: '600', padding: '20px 0px'}}>
                    {t("Preambule")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("Welcome to TheMarketWays. By accessing and using this site, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.")}
                </p>
            </section>

            <section className="mb-6">
                <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                    {t("Contact Information")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("For any questions regarding accommodation, you can use the following email address:")} 
                    <a href="mailto:Mcontact@themarketways.com" className="text-blue-500">
                        contact@themarketways.com
                    </a>.
                </p>
            </section>

            <section className="mb-6">
                <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                    {t("Article 1: Scope")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("These general terms of use apply to all price comparison services offered on this site. We act as an intermediary between users and partner marketplaces.")}
                </p>
            </section>

            <section className="mb-6">
                <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                    {t("Article 2: Price Information")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("The prices displayed on our site are indicative and may vary based on updates made by partner marketplaces. Prices are updated in real-time during a new request and may differ from the updated prices of the day if no new request has been made.")}
                </p>
            </section>

            <section className="mb-6">
                <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                    {t("Article 3: Orders")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("Our site does not offer the possibility to place orders directly. Users must visit the partner marketplaces' sites to complete their purchases. We are not responsible for transactions made on these sites.")}
                </p>
            </section>

            <section className="mb-6">
                <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                    {t("Article 4: Payment Terms")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("Payments are made directly on the partner marketplace's sites. We do not intervene in the payment process.")}
                </p>
            </section>

            <section className="mb-6">
                <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                    {t("Article 5: Payment Security and Fraud Prevention")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("Payment security and fraud prevention are the responsibility of the partner marketplaces. Please refer to their terms and conditions for more information.")}
                </p>
            </section>

            <section className="mb-6">
                <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                    {t("Article 6: Shipping and Delivery")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("Shipping and delivery terms are defined by the partner marketplaces. We are not involved in the delivery process.")}
                </p>
            </section>

            <section className="mb-6">
                <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                    {t("Article 7: Receipt")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("Receipt of products is managed by the partner marketplaces. Please contact them directly for any questions regarding the receipt of your order.")}
                </p>
            </section>

            <section className="mb-6">
                <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                    {t("Article 8: Withdrawal")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("Withdrawal conditions are defined by the partner marketplaces. Please refer to their terms and conditions for details on how to exercise your right of withdrawal.")}
                </p>
            </section>

            <section className="mb-6">
                <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                    {t("Article 9: Warranties")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("Warranties on products are those of the partner marketplaces. For any claims, please contact the customer service of the relevant marketplace directly.")}
                </p>
            </section>

            <section className="mb-6">
                <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                    {t("Article 11: Force Majeure")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("We shall not be held responsible for any delay or failure to perform our obligations in case of force majeure, as defined by the applicable legislation.")}
                </p>
            </section>

            <section className="mb-6">
                <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                    {t("Article 12: Personal Data")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("We place great importance on the protection of your personal data. This data is collected and processed in accordance with our ")} 
                    <a href="/privacy-notice" className="text-blue-500">
                        {t("Privacy Policy")}
                    </a> 
                    {t(". You have the right to access, rectify, and delete your data in accordance with GDPR rules applicable to EU member countries and the data privacy policies of your country.")}
                </p>
            </section>

            <section className="mb-6">
                <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                    {t("Article 13: Code of Conduct")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("By using this site, you agree to adhere to our code of conduct. You must not use the site abusively, including but not limited to harassment, offensive or defamatory statements, and violation of intellectual property rights.")}
                </p>
            </section>

            <section className="mb-6">
                <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                    {t("Article 14: Dispute, Mediation, and Applicable Law")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("In case of a dispute, we encourage you to contact us to find an amicable solution. If not resolved, you may use a consumer mediator. These general terms are governed by the law of [your country]. Any dispute will be subject to the exclusive jurisdiction of the courts of [your country].")}
                </p>
            </section>

            <section className="mb-6">
                <h2 style={{ fontSize: '2.5vh', fontWeight: '600' }}>
                    {t("Article 15: Security Flaws and Hacking")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("We encourage the community to help us maintain a secure site. If you discover a security flaw, please report it to: ")} 
                    <a href="mailto:security-system@marketways.com" className="text-blue-500">
                        security-system@marketways.com
                    </a>
                    {t(". We will review each report, and depending on the severity of the flaw and our ability to reward you, a potential reward may be granted. We greatly appreciate the help of security researchers and commit to handling each report professionally and respectfully.")}
                </p>
            </section>
        </div>
    );
}

export default ConditionOfUse;
