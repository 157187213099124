import React, { useState } from "react";

const CarouselPic = ({ productimg }) => {
    // Transforme selected_image en un tableau d'URL
    const images = Array.isArray(productimg) ? productimg : (productimg ? productimg.split('https://').filter(Boolean).map(url => 'https://' + url) : []);
    
    const [currentIndex, setCurrentIndex] = useState(0);

    // Gestion du déplacement de la souris pour changer d'image
    const handleMouseMove = (e) => {
        const width = e.target.offsetWidth; // Largeur de l'image
        const mouseX = e.nativeEvent.offsetX; // Position X de la souris
        const imageIndex = Math.floor((mouseX / width) * images.length); // Calcul de l'indice
        setCurrentIndex(imageIndex); // Met à jour l'image
    };

    // Gestion du clic sur un point pour changer d'image
    const handleClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div>
            {/* Conteneur de l'image */}
            <div 
                onMouseMove={handleMouseMove} // Ajout de l'événement
                className="w-full h-80 relative mb-4"
            >
                <img
                    src={images[currentIndex]} // Affiche l'image actuelle
                    alt={`Produit ${currentIndex + 1}`}
                    className="w-full h-full object-contain rounded"
                />
            </div>

            {/* Points sous l'image */}
            <div className="flex justify-center m-4 space-x-2">
                {images.map((_, imgIndex) => (
                    <button
                        key={imgIndex}
                        onClick={() => handleClick(imgIndex)} // Gestion du clic
                        className={`w-2 h-2 rounded-full ${
                            imgIndex === currentIndex
                                ? "bg-gray-800 scale-125"
                                : "bg-gray-400"
                        } transition-all duration-300`}
                    ></button>
                ))}
            </div>
        </div>
    );
};

export default CarouselPic;
