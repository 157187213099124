import React from "react";
import { useTranslation } from 'react-i18next'; // Importer le hook pour la traduction

const CookieManagement = () => {
    const { t } = useTranslation(); // Utiliser le hook t pour les traductions

    return (
        <div className="container mx-auto p-4">
            <h1 style={{ fontSize: '3vh', fontWeight: 'bolder' }}>
                <span>{t("Understanding Cookies: Benefits and Privacy")}</span>
            </h1>

            <section className="mb-6">
                <h2 className="text-xl font-semibold mb-2" style={{ fontSize: '2.5vh' , padding: '20px 0px'}}>
                    {t("What Are Cookies?")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("Cookies are small text files stored on your device by your web browser. They help websites remember information about your visit, making your next visit easier and the site more useful to you.")}
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold mb-2" style={{ fontSize: '2.5vh' }}>
                    {t("How We Use Cookies")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("We use cookies for various reasons, such as:")}
                </p>
                <ul className="list-disc list-inside" style={{ fontSize: '1.8vh' }}>
                    <li>{t("Remembering your preferences and settings.")}</li>
                    <li>{t("Improving website functionality and performance.")}</li>
                    <li>{t("Providing personalized content and advertisements.")}</li>
                    <li>{t("Analyzing website traffic and user behavior.")}</li>
                </ul>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold mb-2" style={{ fontSize: '2.5vh' }}>
                    {t("Benefits of Cookies")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("Cookies enhance your browsing experience by:")}
                </p>
                <ul className="list-disc list-inside" style={{ fontSize: '1.8vh' }}>
                    <li>{t("Keeping you logged in as you navigate through the site.")}</li>
                    <li>{t("Remembering items in your shopping cart.")}</li>
                    <li>{t("Showing relevant content based on your interests.")}</li>
                    <li>{t("Speeding up load times and improving site performance.")}</li>
                </ul>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold mb-2" style={{ fontSize: '2.5vh' }}>
                    {t("Protecting Your Data")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("Your privacy is important to us. We ensure that:")}
                </p>
                <ul className="list-disc list-inside" style={{ fontSize: '1.8vh' }}>
                    <li>{t("All collected data is anonymized whenever possible.")}</li>
                    <li>{t("We never share personal information with third parties without your consent.")}</li>
                    <li>{t("We use advanced security measures to protect your data.")}</li>
                </ul>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("You can manage your cookie preferences in your browser settings at any time.")}
                </p>
            </section>

            <section>
                <h2 className="text-xl font-semibold mb-2" style={{ fontSize: '2.5vh' }}>
                    {t("Your Consent")}
                </h2>
                <p style={{ fontSize: '1.8vh', padding: '20px 0px' }}>
                    {t("By continuing to use our site, you consent to our use of cookies as outlined in this policy. If you have any questions or concerns, please contact us.")}
                </p>
            </section>
        </div>
    );
};

export default CookieManagement;
