import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const sanitizeInput = (input) => {
    const element = document.createElement('div');
    element.innerText = input;
    return element.innerHTML;
  };
const Account = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
  
    const navigate = useNavigate();
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    useEffect(() => {
        const syncLoginState = () => {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                try {
                    const parsedUser = JSON.parse(storedUser);
                    if (parsedUser && parsedUser.username) {
                        setIsLoggedIn(true);
                        // Assurez-vous que username ne contient pas de code malveillant
                        setUsername(sanitizeInput(parsedUser.username));
                    }
                } catch (err) {
                    console.error('Error parsing user data from localStorage', err);
                }
            } else {
                setIsLoggedIn(false);
                setUsername('');
            }
        };
    
        // Synchronisation initiale
        syncLoginState();
    
        // Écoute des changements dans localStorage et des événements personnalisés
        window.addEventListener('storage', syncLoginState);
        window.addEventListener('user-login', syncLoginState); // Écouter l'événement personnalisé
    
        return () => {
            window.removeEventListener('storage', syncLoginState);
            window.removeEventListener('user-login', syncLoginState);
        };
    }, []);
    
  
    const handleMouseEnter = () => {
      if (!clicked && !isMobile) {
        setIsOpen(true);
      }
    };
  
    const handleMouseLeave = () => {
      if (!clicked && !isMobile) {
        setIsOpen(false);
      }
    };
  
    const handleIconClick = () => {
      setIsOpen((prevState) => !prevState);
      setClicked((prevState) => !prevState);
    };

    const handleLoginClick = () => {
        navigate('/login');
    };

    const handleRegisterClick = () => {
        navigate('/register');
    };

    const handleLogout = () => {
      localStorage.removeItem('user'); // Clear user data
      setIsLoggedIn(false);
      setUsername('');
      navigate('/'); // Redirect to home
    };

    return (
        <div
            className="relative"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {/* Bouton utilisateur */}
            <div className="rounded-full p-2 hover:shadow-xl transition-all duration-300">
                <button
                    type="button"
                    className="flex items-center justify-center w-10 h-10 rounded-full transition-transform duration-300 hover:scale-125"
                    onClick={handleIconClick}
                >
                    <svg
                        className="w-6 h-6 text-gray-800 transition-colors duration-300 ease-in-out hover:text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        fill="currentColor"
                    >
                        <path d="M10 9a6 6 0 1 1 6 6 6 6 0 0 1-6-6Zm16 20H6a3 3 0 0 1-3-3 9 9 0 0 1 9-9h8a9 9 0 0 1 9 9 3 3 0 0 1-3 3Z"></path>
                    </svg>
                </button>
            </div>

            {/* Menu déroulant */}
            {isOpen && (
                <div className="absolute right-0 top-full bg-white border shadow-lg rounded-lg w-48 z-10">
                    {isLoggedIn ? (
                        <>
                            <div className="p-4">
                                <span className="block text-gray-700 font-semibold text-center">
                                    Bonjour, {username}
                                </span>
                            </div>
                            <button
                                className="block w-full py-2 text-black hover:underline text-center"
                                onClick={handleLogout}
                            >
                                Se déconnecter
                            </button>
                        </>
                    ) : (
                        <>
                            <div className="mt-4 mr-4 ml-4">
                                <button
                                    className="px-8 py-3 bg-gradient-to-r from-red-500 to-red-700 text-white font-semibold rounded-full 
                                    transition-all duration-300 ease-in-out 
                                    hover:from-red-400 hover:to-red-600 hover:shadow-lg hover:scale-105 
                                    focus:outline-none focus:ring-4 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-white 
                                    active:scale-95 active:shadow-sm"
                                    onClick={handleLoginClick}
                                >
                                    Se connecter
                                </button>
                            </div>
                            <button
                                className="block w-full py-2 text-black hover:underline text-center"
                                onClick={handleRegisterClick}
                            >
                                S'inscrire
                            </button>
                        </>
                    )}
                    <div className="my-2 border-t border-gray-300 w-3/4 mx-auto"></div>
                    <ul className="mt-4">
                        <li>
                            <button className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left">
                                Mon Historique
                            </button>
                        </li>
                        <li>
                            <button className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left">
                                Mes favoris
                            </button>
                        </li>
                        <li>
                            <button className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left">
                                Options
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Account;
