import React, { useState, useEffect } from 'react';
import Cookies from "js-cookie";
import { useTranslation } from 'react-i18next';
import cookies from "../assets/img/cookies.png";
import axios from 'axios';
import { getCsrfToken } from '../utils';
const CookiesPopup = () => {
  const { t } = useTranslation();
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Initialiser sur `true` pour afficher la popup par défaut
  const csrfToken = getCsrfToken();
  // Fonction pour envoyer le consentement au backend
  const sendConsentToBackend = async (consent) => {
    const token = Cookies.get('sessionToken');
    try {
       await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/cookie-consent`,
        { consent },
        {
          headers: {
            'Authorization': `${token}`,
            'X-CSRFToken': csrfToken,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      window.location.reload();
    } catch (error) {
      console.error('Erreur lors de l\'envoi des données:', error.response?.data || error.message);
    }
  };

  // Fonction générique pour gérer l'acceptation ou le rejet du consentement
  const handleConsent = (consent) => {
    Cookies.set('cookie_consent', consent, { expires: 365, secure: true });
    sendConsentToBackend(consent);
    setIsPopupVisible(false);
  };

  // Fonctions spécifiques pour accepter ou refuser les cookies
  const handleAccept = () => handleConsent('accepted');
  const handleReject = () => handleConsent('rejected');

  const handleOpenPopup = () => {
    setIsPopupVisible(true); // Ouvrir la popup quand l'utilisateur clique sur l'icône flottante
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false); // Fermer la popup si l'utilisateur clique sur "X"
  };

  // Vérifier si les cookies ont déjà été acceptés ou rejetés
  useEffect(() => {
    const cookieConsent = Cookies.get('cookie_consent');
    if (cookieConsent === 'rejected'){
      setIsPopupVisible(true);
    }
    // Si le cookie 'cookie_consent' existe, ne pas afficher la popup
    if (cookieConsent === 'accepted' || cookieConsent === 'rejected') {
      setIsPopupVisible(false);  // La popup ne s'affiche pas si le consentement est déjà pris
    } else {
      setIsPopupVisible(true);  // Sinon, afficher la popup
    }
  }, []);
  // Vérifier si les cookies ont déjà été acceptés ou rejetés
  
  return (
    <>
      {/* Cookie Modal */}
      {isPopupVisible && (
        <div className="cookies_div fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
          <div className="cookies_popup bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-md">
            
            <div className="cookie_text my-4 text-justify">
              <span className="cookie-title text-xl font-semibold flex justify-center mb-4">
                {t('Miam Miam COOKIESSS!')}
              </span>
              <p className="mb-4">{t('By accepting our cookies, you consent to their use for:')}</p>
              <ul className="list-disc pl-5 mb-4">
                <li>{t('Improving site performance')}</li>
                <li>{t('Analyzing site usage')}</li>
                <li>{t('Personalizing user experience')}</li>
                <li>{t('Saving your preferences')}</li>
              </ul>
              <p className="mb-4">{t('Your information will remain private. To learn more, please read our privacy policy.')}</p>
              <p>{t('By continuing to browse, you accept the use of cookies. See our cookie policy for more details. Thank you for your trust.')}</p>
            </div>

            <div className="cookie_button flex space-x-4 mt-4">
              <button
                onClick={handleAccept}
                className="accept bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-700"
              >
                {t('Accept, Take my Soul')}
              </button>
              <button
                onClick={handleReject}
                className="refuse bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700"
              >
                {t('Reject, I\'m Not Hungry')}
              </button>
            </div>
            <button
              onClick={handleClosePopup}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
            >
              X
            </button>
          </div>
        </div>
      )}

      {/* Icone flottante pour ouvrir les préférences des cookies */}
      <button
        onClick={handleOpenPopup}
        className="fixed bottom-8 right-8 bg-white text-white p-2 rounded-2xl shadow-lg hover:bg-white z-20"
      >
      <img
              src={cookies} // Adaptez le chemin de l'image
              alt={t('cookie')}
              className="cookies w-8 p-0"
            />
      </button>
    </>
  );
};

export default CookiesPopup;
