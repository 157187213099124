import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Product from '../components/product'; 
import { getCsrfToken } from '../utils';
import Cookies from 'js-cookie';
import largebanner from '../assets/img/flat-lay-black-friday-letters-composition (1).jpg'; 
import phonebanner from '../assets/img/black_friday_banner_phone.jpg';
import rocketImage from '../assets/img/spaceship.png';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { IoTimeOutline } from "react-icons/io5";
import { AiOutlineEuro } from "react-icons/ai";
import { IoDiamondOutline } from "react-icons/io5";

const LandingPage = () => {
    const { t } = useTranslation();
    const [banner, setBannerProducts] = useState([]);
    const [category, setCategoryProducts] = useState([]);
    const [error, setError] = useState(null);
    const [visibleCounts, setVisibleCounts] = useState([4, 4]);
    const [symbol, setSymbol] = useState('€');
    const [currency, setCurrency] = useState('EUR');
    const [rate, setRate] = useState(null);
    const csrfToken = getCsrfToken();
    const loadMore = (sectionIndex) => {
        setVisibleCounts((prevCounts) => {
            const newCounts = [...prevCounts];
            newCounts[sectionIndex] += 4; // Ajoute 4 produits à la section spécifique.
            return newCounts;
        });
    };
    
    const productbanner = async () => {
    const token = Cookies.get('sessionToken');

    // Vérification du token
    if (!token) {
        setError('Token de session manquant. Veuillez vous connecter.');
        return;
    }

    // Vérification de l'URL de l'API
    if (!process.env.REACT_APP_BACKEND_URL) {
        setError('URL de l’API non définie. Vérifiez vos variables d’environnement.');
        return;
    }

    try {
        // Appel à l'API
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/product/`,
            {
                withCredentials: true,
                headers: {
                    'Authorization': `${token}`,
                    'X-CSRFToken': csrfToken,
                },
            }
        );

        // Traitement de la réponse
        setBannerProducts(Array.isArray(response.data) ? response.data : []);
    } catch (err) {
        // Gestion des erreurs
        if (err.response) {
            setError(`Erreur serveur : ${err.response.status} ${err.response.statusText}`);
        } else if (err.request) {
            setError('Erreur réseau : Aucun réponse du serveur');
        } else {
            setError(`Erreur inattendue : ${err.message}`);
        }
    }
};

    const productcategory = async () => {
        const token = Cookies.get('sessionToken');
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/product/`, // Modifiez l'URL si nécessaire pour récupérer les catégories
                {
                    withCredentials: true,
                    headers: {
                        'Authorization': `${token}`,
                        'X-CSRFToken': csrfToken,
                    },
                }
            );
            // Assurez-vous que category est un tableau
            setCategoryProducts(Array.isArray(response.data) ? response.data : []);
        } catch (err) {
            if (err.response) {
                setError(`Erreur serveur : ${err.response.status} ${err.response.statusText}`);
            } else if (err.request) {
                setError('Erreur réseau : Aucun réponse du serveur');
            } else {
                setError(`Erreur inattendue : ${err.message}`);
            }
        } 
    };

    const getRate = async (savedCurrency) => {
        const token = Cookies.get('sessionToken');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/rates/`, {
                params: { savedCurrency },
                withCredentials: true,
                headers: {
                    'Authorization': `${token}`,
                    'X-CSRFToken': csrfToken,
                },
            });

            setRate(response.data.rate);
            setSymbol(response.data.symbol_target);
        } catch (error) {
            console.error("Error fetching rates", error);
            setRate(1);
            setSymbol('€');
        }
    };

    useEffect(() => {
        // Fetch initial data
        productbanner();
        productcategory();

        // Get saved currency from cookies
        const savedCurrency = Cookies.get('currency') || 'EUR';
        setCurrency(savedCurrency);
        getRate(savedCurrency); // Fetch the rate for the initial currency
    }, []);

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

    // Utiliser useEffect pour détecter les changements de taille de l'écran
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768); // Change la taille de l'écran si elle devient inférieure à 768px
        };

        window.addEventListener('resize', handleResize);

        // Nettoyage de l'événement lors du démontage du composant
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {/* Section bannière */}
            <div className="m-6 medium:m-12 sm:mx-12 lg:mx-12 overflow-hidden rounded-xl">
                {/* Image par défaut pour les petits écrans */}
                <img
                    src={isSmallScreen ? phonebanner : largebanner}
                    alt="Responsive"
                    className="w-full h-auto object-cover"
                />
            </div>

            {/* Grille de produits */}
            <div className="m-6 medium:m-12 grid small:grid-cols-1 medium:grid-cols-2 lg:grid-cols-3 wide:grid-cols-4 gap-4">
                {banner.slice(0, visibleCounts[0]).map((product, index) => (
                    <Product 
                        key={product.id || index} 
                        product={product} 
                        rate={rate}
                        symbol={symbol}
                        currency={currency}
                    />
                ))}
            </div>

            {visibleCounts[0] < banner.length && (
                <div className="flex justify-center items-center mt-2">
                    <button
                        onClick={() => loadMore(0)} // Chargez plus pour la section 1
                        className="flex items-center px-4 py-2 bg-white text-black rounded-full hover:bg-red-500 hover:text-white transition duration-300 border"
                    >
                        {t("more product")} <FaChevronDown className="ml-2" />
                    </button>
                </div>
            )}

            {/* Section 2 */}
            <div className="m-6 medium:m-12 grid small:grid-cols-1 medium:grid-cols-2 lg:grid-cols-3 wide:grid-cols-4 gap-4">
                {category.slice(0, visibleCounts[1]).map((product, index) => (
                    <Product 
                        key={product.id || index} 
                        product={product} 
                        rate={rate}
                        symbol={symbol}
                        currency={currency}
                    />
                ))}
            </div>

            {visibleCounts[1] < category.length && (
                <div className="flex justify-center items-center mt-2">
                    <button
                        onClick={() => loadMore(1)} // Chargez plus pour la section 2
                        className="flex items-center px-4 py-2 bg-white text-black rounded-full hover:bg-red-500 hover:text-white transition duration-300 border"
                    >
                        {t("more product")} <FaChevronDown className="ml-2" />
                    </button>
                </div>
            )}

            <div className="flex flex-col md:flex-row items-center justify-between m-6 medium:m-12 ">
                {/* Text Content */}
                <div className="md:w-3/7">
                    <h2 className="text-3xl font-bold mb-4"> {t("Why TheMarketWays")}</h2>
                    <p className="text-xl text-gray-700 mb-6">
                        {t("TheMarketWays description")}
                    </p>
                    <div className="space-y-4">
                        <div className="flex items-center space-x-4 bg-white p-8 border rounded-xl shadow-lg">
                            <div className="flex items-center justify-center bg-blue-100 text-blue-600 p-4 rounded-full border border-blue-200 shadow-md">
                                <IoTimeOutline className="text-2xl" />
                            </div>
                            <div>
                                <h3 className="font-bold text-lg text-gray-800">{t("time gain")}</h3>
                                <p className="text-gray-600">
                                    {t("time desc")}
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center space-x-4 bg-white p-8 border rounded-xl shadow-lg">
                            <div className="flex items-center justify-center bg-green-100 text-green-600 p-4 rounded-full border border-green-200 shadow-md">
                                <AiOutlineEuro className="text-2xl" />
                            </div>
                            <div>
                                <h3 className="font-bold text-lg text-gray-800">{t("economy gain")}</h3>
                                <p className="text-gray-600">
                                    {t("economy desc")}
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center space-x-4 bg-white p-8 border rounded-xl shadow-lg">
                            <div className="flex items-center justify-center bg-purple-100 text-purple-600 p-4 rounded-full border border-purple-200 shadow-md">
                                <IoDiamondOutline className="text-2xl" />
                            </div>
                            <div>
                                <h3 className="font-bold text-lg text-gray-800">{t("quality earn")}</h3>
                                <p className="text-gray-600">
                                    {t("quality desc")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Rocket Image */}
                <div className="md:w-1/2 lg:w-2/5 mt-8 md:mt-0 flex justify-center">
                    <img
                        src={rocketImage}
                        alt="Rocket"
                        className="w-full max-w-md lg:max-w-lg h-auto animate-bounce-slow"
                    />
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
