// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Pour détecter automatiquement la langue
import Cookies from 'js-cookie'; // Pour gérer les cookies
import enTranslation from './locales/en.json';
import frTranslation from './locales/fr.json';
import itTranslation from './locales/it.json';
import esTranslation from './locales/es.json';
import deTranslation from './locales/de.json';

i18n
  .use(LanguageDetector) // Utiliser le détecteur de langue
  .use(initReactI18next) // Passer l'instance i18next à React
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      it: {
        translation: itTranslation,
      },
      es: {
        translation: esTranslation,
      },
      de: {
        translation: deTranslation,
      },
    },
    lng: Cookies.get('language') || 'en', // Langue initiale basée sur les cookies ou par défaut
    fallbackLng: 'en', // Langue de secours si aucune traduction n'est disponible
    interpolation: {
      escapeValue: false, // Pas nécessaire pour React
    },
    detection: {
      order: ['cookie', 'localStorage', 'navigator', 'htmlTag'], // Ordre de détection de la langue
      caches: ['cookie'], // Stocker la langue détectée dans les cookies
    },
    debug: false, // Définir sur `true` pour déboguer les traductions dans la console
  });

export default i18n;
