import React from "react";
import { useTranslation } from 'react-i18next'; // Importer le hook pour la traduction

const LegalNotice = () => {
    const { t } = useTranslation(); // Utiliser le hook t pour les traductions

    return (
        <div className="container mx-auto p-4">
            <h1 style={{ fontSize: '3vh', fontWeight: 'bolder' }}>
                {t("Legal Notices")}
            </h1>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600', padding: '20px 0px' }}>
                {t("Site Publisher")}
            </h2>
            <p style={{ fontSize: '1.8vh' }}>
                {t("Company Name")}: TheMarketWays
            </p>
            <p style={{ fontSize: '1.8vh' }}>
                {t("Address")}: 21 Rue d'en bas La Celle sur Morin 77515, France
            </p>
            <p style={{ fontSize: '1.8vh' }}>
                {t("Phone")}: 0603405315
            </p>
            <p style={{ fontSize: '1.8vh' }}>
                {t("Email")}: contact@themarketways.com
            </p>
            <p style={{ fontSize: '1.8vh' }}>
                {t("Company Registration Number (RCS)")}: R.C.S MEAUX 979 196 730
            </p>
            {/* <p style={{ fontSize: '1.8vh' }}>
                {t("VAT Number")}: [Your VAT Number]
            </p> */}
            <p style={{ fontSize: '1.8vh' }}>
                {t("Publication Director")}: Baroin Guillaume
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600', padding: '20px 0px' }}>
                {t("Site Hosting")} 
            </h2>
            <p style={{ fontSize: '1.8vh' }}>
                {t("For any questions regarding accommodation, you can use the following email address:")}: contact@themarketways.com
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600', padding: '20px 0px' }}>
                {t("Terms of Use")}
            </h2>
            <p style={{ fontSize: '1.8vh' }}>
                {t("The use of the TheMarketWays site implies full acceptance of the following general terms of use. These terms of use are subject to change or completion at any time, so users of the TheMarketWays site are invited to consult them regularly.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600', padding: '20px 0px' }}>
                {t("Description of Services")}
            </h2>
            <p style={{ fontSize: '1.8vh' }}>
                {t("The TheMarketWays site aims to provide information about all of the company's activities. TheMarketWays strives to provide accurate information on the TheMarketWays site. However, it cannot be held responsible for omissions, inaccuracies, and deficiencies in the update, whether caused by its own actions or those of third-party partners providing this information.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600', padding: '20px 0px' }}>
                {t("Technical Data Limitations")}
            </h2>
            <p style={{ fontSize: '1.8vh' }}>
                {t("The site uses JavaScript technology. The website cannot be held responsible for material damage resulting from the use of the site. Furthermore, the site user agrees to access the site using recent equipment, free from viruses, and with an up-to-date, latest-generation browser.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600', padding: '20px 0px' }}>
                {t("Intellectual Property and Counterfeiting")}
            </h2>
            <p style={{ fontSize: '1.8vh' }}>
                {t("TheMarketWays owns the intellectual property rights or holds usage rights for all elements accessible on the site, including texts, images, graphics, logos, icons, sounds, and software. Any reproduction, representation, modification, publication, adaptation of all or part of the site's elements, by any means or process, is prohibited, unless prior written permission is obtained from: TheMarketWays.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600', padding: '20px 0px' }}>
                {t("Limitations of Liability")}
            </h2>
            <p style={{ fontSize: '1.8vh' }}>
                {t("TheMarketWays cannot be held responsible for direct and indirect damages caused to the user's equipment during access to the TheMarketWays site, resulting either from the use of equipment not meeting the specifications indicated in the previous point or from the occurrence of a bug or incompatibility.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600', padding: '20px 0px' }}>
                {t("Personal Data Management")}
            </h2>
            <p style={{ fontSize: '1.8vh' }}>
                {t("In France, personal data is protected by law n° 78-87 of January 6, 1978, law n° 2004-801 of August 6, 2004, Article L. 226-13 of the Penal Code, and the European Directive of October 24, 1995.")}
            </p>
            <p style={{ fontSize: '1.8vh' }}>
                {t("During the use of the TheMarketWays site, the following may be collected: the URL of the links through which the user accessed the TheMarketWays site, the user's access provider, and the user's IP address.")}
            </p>
            <p style={{ fontSize: '1.8vh' }}>
                {t("In any case, TheMarketWays only collects personal information about the user for the needs of certain services offered on the TheMarketWays site. The user provides this information knowingly, especially when entering it themselves. The user is then informed of whether providing this information is mandatory or optional.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600', padding: '20px 0px' }}>
                {t("Hypertext Links and Cookies")}
            </h2>
            <p style={{ fontSize: '1.8vh' }}>
                {t("The TheMarketWays site contains a number of hypertext links to other sites, set up with the authorization of TheMarketWays. However, TheMarketWays cannot verify the content of these sites and, consequently, assumes no responsibility in this regard.")}
            </p>
            <p style={{ fontSize: '1.8vh' }}>
                {t("Navigating the TheMarketWays site may result in the installation of cookies on the user's computer. A cookie is a small file that does not identify the user but records information related to the browsing of a computer on a site. The data obtained is intended to facilitate subsequent navigation on the site and to allow various measures of site traffic.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600', padding: '20px 0px' }}>
                {t("Applicable Law and Jurisdiction")}
            </h2>
            <p style={{ fontSize: '1.8vh' }}>
                {t("Any dispute related to the use of the TheMarketWays site is subject to French law. Exclusive jurisdiction is given to the competent courts in Paris.")}
            </p>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600', padding: '20px 0px' }}>
                {t("Main Applicable Laws")}
            </h2>
            <ul className="list-disc list-inside" style={{ fontSize: '1.8vh' }}>
                <li>{t("Law n° 78-17 of January 6, 1978, as amended by law n° 2004-801 of August 6, 2004, relating to information technology, files, and freedoms.")}</li>
                <li>{t("Law n° 2004-575 of June 21, 2004, for confidence in the digital economy.")}</li>
            </ul>

            <h2 style={{ fontSize: '2.5vh', fontWeight: '600', padding: '20px 0px' }}>
                {t("Glossary")}
            </h2>
            <p style={{ fontSize: '1.8vh' }}>
                {t("User: Internet user connecting to and using the aforementioned site.")}
            </p>
            <p style={{ fontSize: '1.8vh' }}>
                {t("Personal Information: ‘information which allows, in any form, directly or indirectly, the identification of the physical persons to whom it applies’ (Article 4 of Law n° 78-17 of January 6, 1978).")}
            </p>
        </div>
    );
};

export default LegalNotice;
