import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { getCsrfToken } from '../utils';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook for translations
import Product from '../components/product';
import axios from 'axios';
const Market = () => {
    const { t } = useTranslation();
    const query = new URLSearchParams(useLocation().search).get('q');
    
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    
    // États pour les taux de conversion et le symbole
    const [rate, setRate] = useState(null);
    const [symbol, setSymbol] = useState('€');
    const [currency, setCurrency] = useState('EUR');
    const csrfToken = getCsrfToken();

    // Fonction pour récupérer le taux de conversion et le symbole
    const getRate = async (savedCurrency) => {
        const token = Cookies.get('sessionToken');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/rates/`, {
                params: { savedCurrency },
                withCredentials: true,
                headers: {
                    'Authorization': `${token}`,
                    'X-CSRFToken': csrfToken,
                },
            });

            setRate(response.data.rate);
            setSymbol(response.data.symbol_target);
        } catch (error) {
            console.error("Error fetching rates", error);
            setRate(1);
            setSymbol('€');
        }
    };

    // Récupération des produits
    const fetchProducts = async (page) => {
        const token = Cookies.get('sessionToken');
        setLoading(true);
        setError(null);

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/search/`,
                    {
                    params: {
                        page: page,
                        search_query: query,
                    },
                    withCredentials: true,
                    headers: {
                        'Authorization': `${token}`,
                        'X-CSRFToken': csrfToken,
                    },
                }
            );
            setProducts((prevProducts) => [...prevProducts, ...response.data]);
        } catch (err) {
            setError(`Erreur : ${err.message}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (query) {
            setProducts([]); // Réinitialiser les produits
            setPage(1); // Réinitialiser la page
        }
    }, [query]);

    useEffect(() => {
        if (query) {
            fetchProducts(page); // Récupérer les produits
        }
    }, [page]);

    useEffect(() => {
        const savedCurrency = Cookies.get('currency') || 'EUR';
        setCurrency(savedCurrency);
        getRate(savedCurrency); // Récupérer les taux quand la devise change
    }, [currency]); // Trigger l'appel API lorsque la devise change

    const handleMoreClick = () => {
        setPage(prevPage => prevPage + 1); 
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen bg-white">
                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
                <p className="ml-4 text-lg font-semibold">{t("Loading...")}</p> {/* Translation for loading message */}
            </div>
        );
    }

    return (
        <div className="p-6 h-full">
            <div className="grid grid-cols-3 lg:grid-cols-4 gap-6">
                <div className="col-span-1 bg-white p-4 rounded-xl shadow-xl border hidden lg:block">
                    <h3 className="text-lg font-semibold mb-4">{t("Options")}</h3> {/* Translated "Options" */}
                    <ul className="space-y-2">
                        <p className="font-semibold">🚧 {t('This section is under construction. Thank you for your patience!')} 🚧</p> {/* Translated message */}
                    </ul>
                </div>

                <div className="col-span-3">
                    <div className='flex justify-between items-center mt-2 mb-2' >
                        <h2 className="text-2xl font-semibold lg:w-2/5">
                            {t("Relevant Products for:")} <span className="text-red-500">{query}</span>
                        </h2>

                        <div className='bg-white p-4 rounded-lg lg:w-3/5 flex justify-between items-center border hidden lg:block'>
                            <p className="font-semibold ">
                                🚧 {t('This section is under construction. Thank you for your patience!')} 🚧
                            </p>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1">
                    {products.map((product, index) => (
                            <Product 
                                key={product.id || index} 
                                product={product} 
                                rate={rate}
                                symbol={symbol}
                                currency={currency}
                            />
                        ))}
                    </div>

                    {/* Load more button */}
                    <div className="flex justify-center mt-4">
                        <button 
                            onClick={handleMoreClick} 
                            className="px-16 py-3 bg-gradient-to-r from-red-500 to-red-700 text-white font-semibold rounded-full transition-all duration-300 
                                    hover:from-red-400 hover:to-red-600 hover:shadow-lg hover:scale-105
                                    focus:outline-none focus:ring-4 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-white 
                                    active:scale-95 active:shadow-sm"
                        >
                            {t('more product')} {/* Translated text for "more product" */}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Market;
