import React, { useState } from 'react';
import axios from 'axios';
import { getCsrfToken } from '../utils';
import Cookies from 'js-cookie';
import { FaGoogle, FaFacebook, FaGithub } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isRegistered, setIsRegistered] = useState(false);
    const navigate = useNavigate();
    const csrfToken = getCsrfToken();
    const handleSubmit = (e) => {
        e.preventDefault();
        
        const token = Cookies.get('sessionToken');
        const user = {
            username,
            email,
            password
        };

        axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/register/`, user, {
            withCredentials: true,
            headers: {
                'Authorization': `${token}`,
                'X-CSRFToken': csrfToken,
            },
        })
        .then((response) => {
            console.log(response.data);
            setIsRegistered(true); // Set registration success state to true
            setTimeout(() => {
            // Redirect to login after 2 seconds
            navigate('/login');
            }, 2000); // 2000ms = 2 seconds
        })
        .catch((error) => {
            console.error('There was an error!', error);
        });
    };

    return (
        <div className="min-h-screen flex items-center justify-center   py-8">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full border max-w-md">
                <h2 className="text-2xl font-semibold text-center mb-6">Create an Account</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <input 
                            type="text" 
                            placeholder="Username" 
                            value={username} 
                            onChange={(e) => setUsername(e.target.value)} 
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                        />
                    </div>
                    <div>
                        <input 
                            type="email" 
                            placeholder="Email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                        />
                    </div>
                    <div>
                        <input 
                            type="password" 
                            placeholder="Password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                        />
                    </div>
                    <div>
                        <button 
                            type="submit" 
                            className="w-full p-3 bg-red-600 text-white font-semibold rounded-md hover:bg-red-500 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            Register
                        </button>
                    </div>
                </form>
                <div className="flex justify-center space-x-4 mt-6">
                    {/* Google Button */}
                    <a
                        href="/auth/google"
                        className="flex items-center justify-center p-4 bg-red-600 text-white rounded-full hover:bg-red-700 transition"
                    >
                        {/* Google Icon from react-icons */}
                        <FaGoogle size={24} />
                    </a>

                    {/* Facebook Button */}
                    <a
                        href="/auth/facebook"
                        className="flex items-center justify-center p-4 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition"
                    >
                        {/* Facebook Icon from react-icons */}
                        <FaFacebook size={24} />
                    </a>

                    {/* GitHub Button */}
                    <a
                        href="/auth/github"
                        className="flex items-center justify-center p-4 bg-gray-800 text-white rounded-full hover:bg-gray-900 transition"
                    >
                        {/* GitHub Icon from react-icons */}
                        <FaGithub size={24} />
                    </a>
                    </div>
                <p className="text-center text-sm text-gray-500 mt-4">
                    Already have an account? 
                    <a href="/login" className="text-red-600 hover:underline"> Login here</a>
                </p>
            </div>
        </div>
    );
};

export default Register;
