import React from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation

const Footer = () => {
    const { t, } = useTranslation(); // Get t function for translations

    return (
        <footer className="bg-[#0A0C17] text-white text-center py-5 font-verdana mt-auto z-10 " style={{ fontFamily: 'Cambria' }}>
            {/* Copyright Section */}
            <div className="text-[2vh]">
                <span>{t("TheMarketWays© 2024 All rights reserved")}</span>
            </div>

            {/* Legal Links */}
            <div className="flex flex-col md:flex-row justify-center items-center gap-4 mt-4 cursor-pointer">
                <a
                    href="/cookie-management"
                    className="text-lg hover:underline"
                >
                    {t("Cookie Management")}
                </a>
                <a
                    href="/legal-notice"
                    className="text-lg hover:underline"
                >
                    {t("Legal Notice")}
                </a>
                <a
                    href="/conditions-of-use"
                    className="text-lg hover:underline"
                >
                    {t("Conditions of Use")}
                </a>
                <a
                    href="/privacy-notice"
                    className="text-lg hover:underline"
                >
                    {t("Privacy Notice")}
                </a>
            </div>

            {/* Amazon Associate Note */}
            <div className="text-[1.8vh] mt-5">
                <span>
                    {t("As an Amazon Associate, TheMarketWays earns money from qualifying purchases.")}
                </span>
            </div>
        </footer>
    );
};

export default Footer;
