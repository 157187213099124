import React, { useState } from 'react';
import axios from '../axios';
import Cookies from 'js-cookie';
import { FaGoogle, FaFacebook, FaGithub } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getCsrfToken } from '../utils';

const Auth = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [isForgotPassword, setIsForgotPassword] = useState(false); // État pour gérer le formulaire mot de passe oublié
    const [attempts, setAttempts] = useState(0); // Compteur de tentatives
    const [isLocked, setIsLocked] = useState(false); // État du verrouillage
    const [lockTime, setLockTime] = useState(null); // Heure de fin du verrouillage

    const navigate = useNavigate();
    const csrfToken = getCsrfToken();

    const handleLogin = (e) => {
        e.preventDefault();

        const token = Cookies.get('sessionToken'); // Récupération du token JWT à partir des cookies

        if (!token) {
            setError('Session token is missing');
            return;
        }

        if (isLocked) {
            const remainingTime = Math.ceil((lockTime - Date.now()) / 1000);
            setError(`Too many failed attempts. Try again in ${remainingTime} seconds.`);
            return;
        }

        const user = { username, password };

        // Requête Axios pour la connexion
        axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/api/login/`, user, {
                withCredentials: true, // Pour envoyer les cookies avec la requête
                headers: {
                    'Authorization': `${token}`, // Ajout du token JWT dans l'en-tête
                    'X-CSRFToken': csrfToken, // Ajout du token CSRF dans l'en-tête
                },
            })
            .then((response) => {
                console.log(response.data);
                // Réinitialise le compteur de tentatives en cas de succès
                setAttempts(0);
                setIsLocked(false);

                // Si la connexion réussit, on sauvegarde les informations utilisateur
                const userData = { username: user.username };
                localStorage.setItem('user', JSON.stringify(userData)); // Sauvegarde des données dans le localStorage
                window.dispatchEvent(new Event('user-login')); // Déclenche un événement personnalisé pour informer de la connexion
                navigate('/'); // Redirige l'utilisateur après la connexion
            })
            .catch((error) => {
                console.error('There was an error!', error);

                // Incrémente le compteur de tentatives en cas d'échec
                setAttempts((prev) => prev + 1);

                if (attempts + 1 >= 5) {
                    setIsLocked(true);
                    setLockTime(Date.now() + 60000); // Verrouille pendant 60 secondes
                    setError('Too many failed attempts. Please try again later.');
                } else {
                    setError('Invalid username or password');
                }
            });
    };

    const handleForgotPassword = (e) => {
        e.preventDefault();

        const token = Cookies.get('sessionToken');

        if (!email || !email.includes('@')) {
            setError('Please enter a valid email address.');
            return;
        }

        axios
            .post(
                `${process.env.REACT_APP_BACKEND_URL}/api/password-reset-email/`,
                { email },
                {
                    withCredentials: true, // Pour envoyer les cookies avec la requête
                    headers: {
                        'Authorization': `${token}`, // Ajout du token JWT dans l'en-tête
                        'X-CSRFToken': csrfToken, // Ajout du token CSRF dans l'en-tête
                    },
                }
            )
            .then((response) => {
                if (response?.data?.message) {
                    console.log('Password reset email sent:', response.data.message);
                    alert('An email has been sent to reset your password.');
                } else {
                    console.log('Unexpected response:', response);
                }
                setIsForgotPassword(false);
            })
            .catch((error) => {
                console.error('Error sending password reset email:', error.response || error);
                setError('There was an error sending the reset email. Please try again.');
            });
    };

    return (
        <div className="flex justify-center items-center h-screen">
            <div className="w-full max-w-md p-6 bg-white shadow-md rounded-lg">
                <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">
                    Login
                </h2>

                {error && (
                    <div className="text-red-500 text-center mb-4">{error}</div>
                )}

                {/* Login Form */}
                {!isForgotPassword ? (
                    <form onSubmit={handleLogin} className="space-y-4">
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
                        />
                        <button
                            type="submit"
                            className="w-full py-3 bg-gradient-to-r from-red-500 to-red-700 text-white font-semibold rounded-xl transition-all duration-300 hover:from-red-400 hover:to-red-600 hover:shadow-lg hover:scale-105 focus:outline-none focus:ring-4 focus:ring-red-500 focus:ring-offset-2 active:scale-95 active:shadow-sm"
                        >
                            Login
                        </button>
                    </form>
                ) : (
                    <form onSubmit={handleForgotPassword} className="space-y-4">
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
                        />
                        <button
                            type="submit"
                            className="w-full py-3 bg-gradient-to-r from-red-500 to-red-700 text-white font-semibold rounded-xl transition-all duration-300 hover:from-red-400 hover:to-red-600 hover:shadow-lg hover:scale-105 focus:outline-none focus:ring-4 focus:ring-red-500 focus:ring-offset-2 active:scale-95 active:shadow-sm"
                        >
                            Send Reset Email
                        </button>
                    </form>
                )}

                <div className="mt-4 text-center">
                    {!isForgotPassword ? (
                        <span
                            className="text-blue-500 cursor-pointer"
                            onClick={() => navigate('/register')}
                        >
                            Don't have an account? Sign up
                        </span>
                    ) : (
                        <span
                            className="text-blue-500 cursor-pointer"
                            onClick={() => setIsForgotPassword(false)}
                        >
                            Back to login
                        </span>
                    )}
                </div>

                {!isForgotPassword && (
                    <div className="mt-4 text-center">
                        <span
                            className="text-blue-500 cursor-pointer"
                            onClick={() => setIsForgotPassword(true)}
                        >
                            Forgot Password?
                        </span>
                    </div>
                )}

                <div className="my-4 flex items-center justify-center">
                    <span className="text-gray-500">or</span>
                </div>

                <div className="flex justify-center space-x-4">
                    <a
                        href="/auth/google"
                        className="flex items-center justify-center p-3 bg-red-600 text-white rounded-full hover:bg-red-700 transition"
                    >
                        <FaGoogle size={24} />
                    </a>
                    <a
                        href="/auth/facebook"
                        className="flex items-center justify-center p-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition"
                    >
                        <FaFacebook size={24} />
                    </a>
                    <a
                        href="/auth/github"
                        className="flex items-center justify-center p-3 bg-gray-800 text-white rounded-full hover:bg-gray-900 transition"
                    >
                        <FaGithub size={24} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Auth;
