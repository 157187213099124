import axios from 'axios';
import Cookie from 'js-cookie';

// Fonction pour récupérer le token et le CSRF
const getTokenAndCsrf = () => {
  const token = Cookie.get('sessionToken');
  const csrfToken = Cookie.get('csrftoken');
  return { token, csrfToken };
};

// Configure Axios avec des en-têtes par défaut
const configureAxios = () => {
  const { token, csrfToken } = getTokenAndCsrf();
  
  if (token) {
    axios.defaults.headers.common['Authorization'] = `${token}`;
  }
  if (csrfToken) {
    axios.defaults.headers.common['X-CSRFToken'] = csrfToken;
  }
};

// Initialiser la configuration Axios
configureAxios();

// Exporter Axios pour utilisation dans d'autres parties de l'application
export default axios;
